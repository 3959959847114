import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CampoTexto from "../../../Componentes/CampoTexto/CampoTexto";
import { useState } from "react";
import { Cookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Swal from "sweetalert2";
import Acticvidad from "./Actividad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const Usuario = () => {
  const { cookies } = new Cookies();
  const navigate = useNavigate();
  const { id } = useParams();
  const [usuario, setUsuario] = useState({
    CorreoElectronico: "",
    Estatus: 0,
    FechaCreacion: "",
    IdUsuario: "",
    Intentos: 0,
    Nombre: "",
  });

  const obtenerUsuario = async () => {
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/usuario?id=${id}`;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: token },
      });
      if (res.status === 200) {
        setUsuario(res.data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editarUsuario = async (e) => {
    let token = document.cookie.split("=")[1].split(";")[0];
    e.preventDefault();
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/usuario`;
    const body = { ...usuario, Id: id };
    try {
      const res = await axios.put(url, JSON.stringify(body), {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (res.status === 200) {
        Swal.fire("Usuario actualizado", "", "success");
        navigate("../Usuarios");
      }
    } catch (error) {
      const res = error.response;
    }
  };

  function fechaLargaConTexto(fecha) {
    const diasSemana = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    if (!(fecha instanceof Date)) {
      return fecha;
    }

    const dia = fecha.getDate();
    const diaSemana = diasSemana[fecha.getDay()];
    const mes = meses[fecha.getMonth()];
    const año = fecha.getFullYear();

    return `${diaSemana}, ${dia} de ${mes} de ${año}`;
  }

  useEffect(() => {
    obtenerUsuario();
  }, []);
  return (
    <main className="usuario">
      <h1>Usuario</h1>
      <div className="contenedor">
        <form onSubmit={(e) => editarUsuario(e)}>
          <CampoTexto
            label="Nombre"
            value={usuario.Nombre}
            onChange={(e) => setUsuario({ ...usuario, Nombre: e.target.value })}
          />
          <CampoTexto
            label="Correo Electrónico"
            value={usuario.CorreoElectronico}
            onChange={(e) =>
              setUsuario({ ...usuario, CorreoElectronico: e.target.value })
            }
          />
          <FormControl fullWidth size="small">
            <InputLabel>Estatus</InputLabel>
            <Select
              onChange={(e) =>
                setUsuario({ ...usuario, Estatus: e.target.value })
              }
              value={usuario.Estatus}
              fullWidth
            >
              <MenuItem value={1}>Activo</MenuItem>
              <MenuItem value={0}>Pendiente de Verificacion</MenuItem>
              <MenuItem value={-1}>Deshabilitado</MenuItem>
            </Select>
          </FormControl>
          <h5>{usuario.Intentos} Intentos de sesión fallidos</h5>
          <h6>Creado el: {usuario.FechaCreacion}</h6>
          <Button type="submit" variant="contained" color="primary">
            Actualizar
          </Button>
        </form>

        <div className="control-acciones">
          <button onClick={() => navigate("./Actividad")}>
            <FontAwesomeIcon icon={faEye} />
            <h3>Ver Actividad</h3>
          </button>
        </div>
      </div>
      
    </main>
  );
};

export default Usuario;
