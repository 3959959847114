import React, { useState } from "react";
import {  faUserGroup, faFile, faFolder, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

const SideBar = () => {
  
  
  const navigate = useNavigate();
  const cookies = new Cookies();
  const nombreUsuario = cookies.get("Nombre");
  const [botonActivo, setBotonActivo] = useState("Inicio")
  
  //Funcion para asignar botones
  const handleButton = (boton,destino) => {
    setBotonActivo(boton)
  navigate( destino)
  }
  return (
    <aside>
      <section className="menu">
        <div  onClick={e => handleButton(e)} className="opcion" >
          <h1>fideapech</h1>
        </div>
        <div className="nombre"></div>
        {nombreUsuario && <p>{nombreUsuario}</p>}
      </section>

      <section className="contenedor-opciones">
      <div  onClick={() => handleButton("Inicio","/Inicio")}   className={botonActivo === "Inicio" ? "contenedor-boton activo" : "contenedor-boton"} >
          <FontAwesomeIcon icon={faHome} className="icono" />
          <p>Inicio</p>
        </div>
        <div  onClick={() => handleButton("Catalogos","/Inicio/Catalogos")}   className={botonActivo === "Catalogos" ? "contenedor-boton activo" : "contenedor-boton"} >
          <FontAwesomeIcon icon={faFile} className="icono" />
          <p>Catálogos</p>
        </div>
        <div  className={botonActivo === "Solicitudes" ? "contenedor-boton activo" : "contenedor-boton"}  onClick={() => handleButton("Solicitudes","/Inicio/Solicitudes")}  >
          <FontAwesomeIcon icon={faFolder} className="icono" />
          <p>Solicitudes</p>
        </div>
        <div  onClick={() => handleButton("Agentes","/Inicio/Agentes")}  className={botonActivo === "Agentes" ? "contenedor-boton activo" : "contenedor-boton"}>
          <FontAwesomeIcon icon={faUserGroup} className="icono" />
          <p>Agentes</p>
        </div>
        <div  onClick={() => handleButton("Usuarios","/Inicio/Usuarios")}  className={botonActivo === "Usuarios" ? "contenedor-boton activo" : "contenedor-boton"}>
          <FontAwesomeIcon icon={faUserGroup} className="icono" />
          <p>Usuarios</p>
        </div>
      </section>
      <section className="configuracion">
         <div onClick={() => console.log(this)} className="opcion">
          <p>Cerrar Sesión</p>
        </div>
      </section>
    </aside>
  );
};

export default SideBar;
