import { useEffect, useState } from "react";
import CampoTexto from "../../../Componentes/CampoTexto/CampoTexto";
import { Button, FormGroup, Switch, FormControlLabel } from "@mui/material";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Formulario from "./Componentes/Formulario";
import Documento from "./Componentes/Documento";
import Documentos from "./Componentes/Documentos";
const Modalidad = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [cargando, setCargando] = useState(false);

  return (
    <main className="edicion-catalogo">
      <div className="controles">
        <h1>Modalidad</h1>
        <Formulario cargando={cargando} setCargando={setCargando} />
        <Button onClick={() => navigate(`/Inicio/Catalogos/Modalidades/${id}/Documentos`)} variant="contained" color="secondary">
          Asignar Documentos
        </Button>
      </div>
      <div className="outlet">
       <Outlet/>
      </div>
    </main>
  );
};

export default Modalidad;
