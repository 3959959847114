import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  faPencil,
  faUserGroup,
  faTrash,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";
import axios from "axios";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, ButtonBase } from "@mui/material";
import Paginador from "../../Componentes/Paginador/Paginador";

const cookies = new Cookies();

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [modalEliminarUsuario, setModalEliminarUsuario] = useState(false);
  const [UsuarioAEliminar, setUsuarioAEliminar] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [busqueda, setBusqueda] = useState("");
  const navigate = useNavigate();

  const mostrarModalEliminar = (Usuario) => {
    Swal.fire({
      title: `¿Estás seguro que quieres elminar a ${Usuario.Nombre}?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        eliminarUsuario(Usuario.IdUsuario);
      }
    });
  };

  const obtenerUsuarios = async () => {
    let url = "";
    if (busqueda) {
      url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/usuarios?pagina=${pagina}&busqueda=${busqueda}`;
    } else {
      url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/usuarios?pagina=${pagina}`;
    }

    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: cookies.get("token"),
        },
      });
      if (res.status === 200) {
        console.log(res.data);
        setUsuarios(res.data.usuarios);
        setTotalPaginas(res.data.paginas);
      } else {
        console.log("Error al obtener los usuarios");
      }
    } catch (error) {
      setUsuarios([]);
      const res = error.response;
      if (res && res.status === 404) {
        console.log("No hay usuarios");
      }
    }
  };

  const eliminarUsuario = async (UsuarioId) => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/Usuario?IdUsuario=${UsuarioId}`;
    try {
      const res = await axios.delete(url, {
        headers: {
          Authorization: cookies.get("token"),
        },
      });
      console.log(res);
      console.log(res.status);
      if (res.status == 200) {
        console.log("Usuario eliminado");
        obtenerUsuarios();
      } else {
        console.log("Error al eliminar Usuario");
      }
    } catch (error) {}

    console.log(`Trash clicked for Usuario ID: ${UsuarioId}`);
  };

  //Funcion para editar un Usuario

  const editarUsuario = async (UsuarioId) => {
    console.log(`Pencil clicked for Usuario ID: ${UsuarioId}`);
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/Usuario?IdUsuario=${UsuarioId}`;
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: cookies.get("token"),
        },
      });
    } catch (error) {
      console.log("Error al editar Usuario");
    }
  };

  useEffect(() => {
    const debounceDelay = 500; // Tiempo de espera en milisegundos
    let timeoutId;
    if (busqueda) {
      timeoutId = setTimeout(() => {
        obtenerUsuarios();
      }, debounceDelay);
    } else {
      obtenerUsuarios();
    }
  }, [pagina, busqueda]);

  const ModalEliminar = ({ Usuario }) => {
    return (
      <>
        <h2>¿Estás seguro que deseas eliminar a {Usuario.Nombre}?</h2>
        <div className="contenedor-menu">
          <Button>Cancelar</Button>
          <Button
            onClick={() => eliminarUsuario(Usuario.IdUsuario)}
            color="error"
          >
            Eliminar
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="usuarios">
      <h1>Usuarios</h1>
      <TextField
        name="Busqueda"
        value={busqueda}
        onChange={(e) => setBusqueda(e.target.value)}
        size="small"
        fullWidth
        label="Buscar por correo electronico"
      />
      <Paginador
        pagina={pagina}
        totalPaginas={totalPaginas}
        handlerPagina={setPagina}
      />
      <div className="contenedor-usuarios">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo Electronico</th>
              <th className="centrado">Estatus</th>
              <th className="centrado">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((Usuario) => (
              <tr key={Usuario.IdUsuario}>
                <td>{Usuario.Nombre}</td>
                <td>{Usuario.CorreoElectronico}</td>
                <td className="centrado">
                  {Usuario.Estatus == 1 ? (
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#00D609" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#D9D9D9" }}
                    />
                  )}
                </td>

                <td>
                  <div className="contenedor-opciones">
                    <button
                      onClick={() =>
                        navigate(
                          `../Usuarios/${Usuario.IdUsuario}`
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPencil} className="icono" />
                    </button>
                    <button onClick={() => mostrarModalEliminar(Usuario)}>
                      <FontAwesomeIcon icon={faTrash} className="icono" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Usuarios;
