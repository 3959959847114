import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import NotFound from "../../../../Componentes/NotFound/NotFound";
import { Button } from "@mui/material";
import Garantia from "./Componentes/Referencia";

const DatosReferencias = () => {
  const { id } = useParams();
  const [referencias, setReferencias] = useState([]);
  const obtenerreferencias = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/solicitud/datos-referencias?Id=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setReferencias(res.data.Referencias);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    obtenerreferencias();
  }, []);

  const json = {
    Nombre: "garantia.Nombre",
    RFC: "garantia.RFC",
    Telefono: "garantia.Telefono",
    Celular: "garantia.Celular",
    Calle: "garantia.Calle",
    CodigoPostal: "garantia.CodigoPostal",
    ArraigoAnos: "garantia.ArraigoAnos",
    ArraigoMeses: "garantia.ArraigoMeses",
    FechaNacimiento: "j",
    Colonia: "garantia.Colonia",
    Municipio: "garantia.Municipio",
    Estado: "garantia.Estado",
    EstadoCivil: "garantia.EstadoCivil",
    DatosConyuge: "garantia.DatosConyuge",
  };
  return (
    <>
      <h2>Datos de las Referencias</h2>
      {referencias.length === 0 ? (
        <NotFound title={"El usuario no ha cargado ninguna referencia"} />
      ) : null}

      <div className="contenedor-garantias">
            {referencias.map((referencia) => (
                <Garantia key={referencia.Id} referencia={referencia} />
            ))}
      </div>
    </>
  );
};

export default DatosReferencias;
