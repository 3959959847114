import React, { createContext, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import useRequest from "../../../../../Hooks/useRequest/useRequest";
export const DatosSolicitanteContext = createContext();

const DatosSolicitanteProvider = (props) => {
  const { id } = useParams();
  const [datosSolicitante, setDatosSolicitante] = useState(false);
  const peticionDatosSolicitante = useRequest("admin/solicitud/datos-solicitante");
  const obtenerDatosSolicitante = async () => {
      const response = await peticionDatosSolicitante.get({ Id: id });
      if (response.status === 200) {
        setDatosSolicitante(response.data);
      } else {
        setDatosSolicitante([]);
      }

  };

  return (
    <DatosSolicitanteContext.Provider
      value={{
        datosSolicitante,
        setDatosSolicitante,
        obtenerDatosSolicitante,
        peticionDatosSolicitante
      }}
    >
      {props.children}
    </DatosSolicitanteContext.Provider>
  );
};

export default DatosSolicitanteProvider;
