import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useRequest from "../../../../Hooks/useRequest/useRequest";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import Documento from "./Documento";

const DocumentosSolicitante = () => {
  const peticionDocumentos = useRequest("/admin/documentos/solicitudes");
  const { id } = useParams();
  const [preautorizacion, setPreautorizacion] = useState([]);
  const [autorizacion, setAutorizacion] = useState([]);

  const obtenerDocumentos = async () => {
    const response = await peticionDocumentos.get({
      IdSolicitud: id,
    });
    if (response.status === 200) {
      setPreautorizacion(response.data.PreAutorizacion);
      setAutorizacion(response.data.Autorizacion);
    }
  };

  useEffect(() => {
    obtenerDocumentos();
  }, []);

  return (
    <section className="documentos-solicitante">
      <div className="contenedor-tabla">
        <table>
          <thead>
            <tr>
              <th>Documento</th>
              <th>EstatusDocumento</th>
              <th>Tipo</th>
              <div>Acciones</div>
            </tr>
          </thead>
          <tbody>
            {preautorizacion.map((documento) => (
                <Documento documento={documento} tipo="Preautorizacion" />
            ))}
            {
                autorizacion.map((documento) => (
                    <Documento documento={documento} tipo="Autorizacion" />
                ))
            }
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default DocumentosSolicitante;
