const Dashboard = () => {
    return ( 
        <section className="dashboard modulo">
            <div className="contenedor-bienvenida">
                <div className="contenedor-textos">
                    <h1>Hola Alex</h1>
                    <p>Tienes 12 nuevas solicitudes, es un buen día para empezar</p>
                </div>
                <div className="contenedor-imagen">
                    <img src="/assets/dog.svg" alt="dog" />
                </div>
            </div>

            <div className="estadisticas">
                <h2>Números</h2>
                <div className="contenedor-estadisticas">
                
                    <div className="estadistica">
                        <h2>3</h2>
                        <div className="descripcion">
                        <h3>Nuevas Solicitudes</h3>
                        <p>(9) en total</p>
                        </div>
                        <div className="grafica">
                            <p>33%</p>
                        </div>
                    </div>
                  
                    <div className="estadistica">
                        <h2>3</h2>
                        <div className="descripcion">
                        <h3>Nuevas Solicitudes</h3>
                        <p>(9) en total</p>
                        </div>
                        <div className="grafica">
                            <p>33%</p>
                        </div>
                    </div>
                    <div className="estadistica">
                        <h2>3</h2>
                        <div className="descripcion">
                        <h3>Nuevas Solicitudes</h3>
                        <p>(9) en total</p>
                        </div>
                        <div className="grafica">
                            <p>33%</p>
                        </div>
                    </div>
                    <div className="estadistica">
                        <h2>3</h2>
                        <div className="descripcion">
                        <h3>Nuevas Solicitudes</h3>
                        <p>(9) en total</p>
                        </div>
                        <div className="grafica">
                            <p>33%</p>
                        </div>
                    </div>
                </div>

            </div>
            
            <div className="actividad">
                    <h2>Actividad</h2>
                    <div className="contenedor-tabla">
                        <table>
                            <thead>
                                <tr>
                                    <th>Solicitante</th>
                                    <th>Accion</th>
                                    <th>Fecha</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Carlos</td>
                                <td>Carlos</td>
                                <td>Carlos</td>
                                <td>Carlos</td>

                                </tr>
                                </tbody>
                        </table>
                                                                    
                                
                    </div>
                </div>
                <div className="solicitudes">
                    <h2>Nuevas Solicitudes</h2>
                    <div className="contenedor-tabla">
                        <table>
                            <thead>
                                <tr>
                                    <th>Solicitante</th>
                                    <th>Accion</th>
                                    <th>Fecha</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Carlos</td>
                                <td>Carlos</td>
                                <td>Carlos</td>
                                <td>Carlos</td>

                                </tr>
                                </tbody>
                        </table>
                                                                    
                                
                    </div>
                </div>
                <div className="usuarios">
                    <h2>Nuevos</h2>
                    <div className="contenedor-tabla">
                        <table>
                            <thead>
                                <tr>
                                    <th>Solicitante</th>
                                    <th>Accion</th>
                                    <th>Fecha</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Carlos</td>
                                <td>Carlos</td>
                                <td>Carlos</td>
                                <td>Carlos</td>

                                </tr>
                                </tbody>
                        </table>
                                                                    
                                
                    </div>
                </div>
        </section>
     );
}
 
export default Dashboard;