import { useState } from "react";
import Paginador from "../../../Componentes/Paginador/Paginador";
import axios from "axios";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
const Acticvidad = () => {
  const { id } = useParams();
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, settotalPaginas] = useState(1);
  const [registros, setRegistros] = useState([]);

  const obtenerActividadUsuario = async () => {
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/usuario/actividad?id=${id}&pagina=${pagina}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      if (res.status === 200) {
        setRegistros(res.data.registros);
      } else {
      }
    } catch (error) {
      Swal.fire(error.name, error.message, "error");
    }
  };

  useEffect(() => {
    obtenerActividadUsuario();
  }, [pagina]);

  return (
    <section className="actividad">
      <h2>Actividad</h2>
      <Paginador
        pagina={1}
        handlerPagina={settotalPaginas}
        totalPaginas={totalPaginas}
      />
      <div className="contenedor-tabla">
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Acción</th>
              <th>Descripcion</th>
              <th>Afectado por</th>
            </tr>
          </thead>
          <tbody>
            {registros.map((registro) => (
              <tr key={registro.Id}>
                <td>{registro.Fecha}</td>
                <td>{registro.Tipo}</td>
                <td>{registro.Descripcion}</td>
                <td>{registro.Agente}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Acticvidad;
