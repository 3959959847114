import { useState,useEffect } from "react";
import CampoTexto from "../../../../Componentes/CampoTexto/CampoTexto";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import axios from "axios";
const Formulario = ({cargando, setCargando}) => {
    const {id} = useParams();
    const navigate = useNavigate();
  const [modalidad, setModalidad] = useState({
    Modalidad: "",
    Descripcion: "",
    Ilustracion: false,
  });

  const obtenerPersonalidadJuridica = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/modalidad?Id=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setModalidad(res.data);
      }
    } catch (error) {}
  };
  const crearModalidad= async (e) => {
    e.preventDefault();
    setCargando(true);
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/modalidad`;
    const form = new FormData();
    form.append(
      "Modalidad",
      modalidad.Modalidad
    );
    form.append("Descripcion", modalidad.Descripcion);
    if (modalidad.Ilustracion) {
      form.append("Ilustracion", modalidad.Ilustracion[0]);
    }
    try {
      const res = await axios.post(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });
      setCargando(false);
      if (res.status === 200) {
        navigate("/Inicio/Catalogos/Modalidades")
      }
    } catch (error) {
      setCargando(false);
    }
  };
  const actualizarModalidad= async (e) => {
    e.preventDefault();
    setCargando(true);
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/modalidad`;
    const form = new FormData();
    form.append("IdModalidad", id);
    form.append(
      "Modalidad",
      modalidad.Modalidad
    );
    form.append("Descripcion", modalidad.Descripcion);
    if (modalidad.Ilustracion) {
      form.append("Ilustracion", modalidad.Ilustracion[0]);
    }
    try {
      const res = await axios.put(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });
      setCargando(false);
      if (res.status === 200) {
        navigate("/Inicio/Catalogos/Modalidades")
      }
    } catch (error) {
      setCargando(false);
    }
  };

  const enviarFormulario = (e) => {
    e.preventDefault();
    if (id !== "Nuevo") {
      actualizarModalidad(e);
    } else {
      crearModalidad(e);
    }
  }
  useEffect(() => {
    if (id) {
      obtenerPersonalidadJuridica();
    }
  }, []);
  return (
    <form onSubmit={(e) => enviarFormulario(e)}>
      <CampoTexto
        label="Modalidad"
        placeholder="Modalidad"
        name="Modalidad"
        required={true}
        value={modalidad.Modalidad}
        onChange={(e) =>
          setModalidad({
            ...modalidad,
            Modalidad: e.target.value,
          })
        }
      />
      <CampoTexto
        label="Descripción"
        placeholder="Descripcin"
        name="Descripcion"
        required={true}
        value={modalidad.Descripcion}
        onChange={(e) =>
          setModalidad({
            ...modalidad,
            Descripcion: e.target.value,
          })
        }
      />
      <input
        type="file"
        {...(id === "Nuevo" ? { required: true } : {})}
        onChange={(e) =>
          setModalidad({
            ...modalidad,
            Ilustracion: e.target.files,
          })
        }
      />
      <Button disabled={cargando} variant="contained" type="submit">
        {cargando ? "Cargando..." : "Actualizar"}
      </Button>
    </form>
  );
};

export default Formulario;
