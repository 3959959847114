import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  faPencil,
  faUserGroup,
  faTrash,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, ButtonBase } from "@mui/material";

const cookies = new Cookies();

const ListaAgentes = () => {
  const [agentes, setAgentes] = useState([]);
  const [modalEliminarAgente, setModalEliminarAgente] = useState(false);
  const [agenteAEliminar, setAgenteAEliminar] = useState(false)
  const navigate = useNavigate();

  const mostrarModalEliminar = (agente) => {
    Swal.fire({
      title: `¿Estás seguro que quieres elminar a ${agente.Nombre}?`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        eliminarAgente(agente.IdAgente)
      } 
    })
  }

  const obtenerAgentes = async (setAGentes) => {
   
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/agentes`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: cookies.get("token"),
        },
      });
      if (res.status === 200) {
        console.log(res.data);
        setAgentes(res.data.agentes);
      } else {
        console.log("Error al obtener los agentes");
      }
    } catch (error) {
      setAgentes([]);
      const res = error.response;
      if (res && res.status === 404) {
        console.log("No hay agentes");
      }
    }
  };

  const eliminarAgente = async (agenteId) => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/agente?IdAgente=${agenteId}`;
    try {
      const res = await axios.delete(url, {
        headers: {
          Authorization: cookies.get("token"),
        },
      });
      console.log(res);
      console.log(res.status);
      if (res.status == 200) {
        console.log("agente eliminado");
        obtenerAgentes();
      } else {
        console.log("Error al eliminar agente");
      }
    } catch (error) {}

    console.log(`Trash clicked for agente ID: ${agenteId}`);
  };

  //Funcion para editar un agente

  const editarAgente = async (agenteId) => {
    console.log(`Pencil clicked for agente ID: ${agenteId}`);
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/agente?IdAgente=${agenteId}`;
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: cookies.get("token"),
        },
      });
    } catch (error) {
      console.log("Error al editar agente");
    }
  };

  useEffect(() => {
    
    obtenerAgentes();
  }, []);

  const ModalEliminar = ({ agente }) => {
    return (
      <>
        <h2>¿Estás seguro que deseas eliminar a {agente.Nombre}?</h2>
        <div className="contenedor-menu">
          <Button>Cancelar</Button>
          <Button onClick={() => eliminarAgente(agente.IdAgente)} color="error">
            Eliminar
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="agentes">
      <Button
        onClick={() => navigate("../Agentes/NuevoAgente/Nuevo")}
        variant="contained"
        style={{ gap: 10 }}
      >
        <FontAwesomeIcon icon={faUserGroup} className="icono" />
        Crear Agente
      </Button>
      <div className="contenedor-agentes">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th className="centrado">Administrador</th>
              <th className="centrado">Estatus</th>
              <th className="centrado">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {agentes.map((agente) => (
              <tr key={agente.IdAgente}>
                <td>{agente.Nombre}</td>
                <td className="centrado">
                  {agente.Administrador == 1 ? (
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#00D609" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#D9D9D9" }}
                    />
                  )}
                </td>
                <td className="centrado">
                  {agente.Estatus == 1 ? (
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#00D609" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{ color: "#D9D9D9" }}
                    />
                  )}
                </td>
                <td>
                  <div className="contenedor-opciones">


                    <button
                      onClick={() =>
                        navigate(`../Agentes/NuevoAgente/${agente.IdAgente}`)
                      }
                    >
                      <FontAwesomeIcon icon={faPencil} className="icono" />
                    </button>
                    <button
                      onClick={() =>
                        mostrarModalEliminar(agente)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} className="icono" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListaAgentes;
