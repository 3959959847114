import { useEffect, useState } from "react";
import CampoTexto from "../../../Componentes/CampoTexto/CampoTexto";
import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { json, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const Documento = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(false);

  const [documento, setDocumento] = useState({
    Documento: "",
    Descripcion: "",
    Estado: false,
    Multiple: false,
    MimeType: "application/pdf",
    Eventual: false,
  });

  const obtenerProductosFinancieros = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/documento?Id=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setDocumento(res.data);
      }
    } catch (error) {}
  };
  const crearDocumento = async (e) => {
    e.preventDefault();
    setCargando(true);
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/documento`;

    try {
      const res = await axios.post(url, JSON.stringify(documento), {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      setCargando(false);
      if (res.status === 200) {
        navigate("/Inicio/Catalogos/Documentos");
      }
    } catch (error) {
      setCargando(false);
    }
  };
  const actualizardocumento = async (e) => {
    e.preventDefault();
    setCargando(true);
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/documento`;
    try {
      const res = await axios.put(
        url,
        JSON.stringify({ ...documento, IdDocumento: id }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      setCargando(false);
      if (res.status === 200) {
        navigate("/Inicio/Catalogos/Documentos");
      }
    } catch (error) {
      setCargando(false);
    }
  };

  const enviarFormulario = (e) => {
    e.preventDefault();
    if (id !== "Nuevo") {
      actualizardocumento(e);
    } else {
      crearDocumento(e);
    }
  };
  useEffect(() => {
    if (id !== "Nuevo") {
      obtenerProductosFinancieros();
    }
  }, []);
  return (
    <main className="edicion-catalogo">
      <h1>Personalidad Jurídica</h1>
      <form onSubmit={(e) => enviarFormulario(e)}>
        <CampoTexto
          label="Documento"
          placeholder="Documento"
          name="Documento"
          required={true}
          value={documento.Documento}
          onChange={(e) =>
            setDocumento({
              ...documento,
              Documento: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Descripcion"
          placeholder="Descripcion"
          name="Descripcion"
          required={true}
          value={documento.Descripcion}
          onChange={(e) =>
            setDocumento({
              ...documento,
              Descripcion: e.target.value,
            })
          }
        />

        <FormControl size="small" fullWidth>
          <InputLabel>Tipo de Documento</InputLabel>
          <Select
            value={documento.MimeType}
            onChange={(e) =>
              setDocumento({ ...documento, MimeType: e.target.value })
            }
          >
            <MenuItem value={"application/pdf"}>PDF</MenuItem>
            <MenuItem value={"application/image"}>Image</MenuItem>
            <MenuItem value={"application/word"}>Word</MenuItem>
            <MenuItem value={"application/excel"}>Excel</MenuItem>
          </Select>
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                value={documento.Estado}
                checked={documento.Estado}
                onChange={(e) =>
                  setDocumento({ ...documento, Estado: e.target.checked })
                }
              />
            }
            label="Estado"
          />
          <FormControlLabel
            control={
              <Switch
                checked={documento.Multiple}
                value={documento.Multiple}
                onChange={(e) =>
                  setDocumento({ ...documento, Multiple: e.target.checked })
                }
              />
            }
            label="Multiple"
          />
          <FormControlLabel
            control={
              <Switch
                checked={documento.Eventual}
                value={documento.Eventual}
                onChange={(e) =>
                  setDocumento({ ...documento, Eventual: e.target.checked })
                }
              />
            }
            label="Eventual"
          />
        </FormGroup>

        <Button disabled={cargando} variant="contained" type="submit">
          {cargando ? "Cargando..." : "Actualizar"}
        </Button>
      </form>
    </main>
  );
};

export default Documento;
