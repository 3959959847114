import { faCar, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useRef } from "react";
import Swal from "sweetalert2";

const Garantia = ({ garantia }) => {
  const avalRef = useRef(0);
  const [aval, setaval] = useState(false);
  const mostrarAval = () => {
    if (garantia.Propio) {
      Swal.fire(
        "Garantia propia",
        "Esta garantía no tiene más detalles",
        "info"
      );
      return;
    } else {
      setaval(!aval);
      if (aval) {
        avalRef.current.style.display = "none";
      } else {
        avalRef.current.style.display = "block";
      }
    }
  };
  return (
    <div onClick={mostrarAval} className="garantia">
      <div className="informacion">
      <FontAwesomeIcon className="icono" icon={garantia.TipoGarantia == 2 ? faCar: faHome} />{" "}
        <h4>
        
          {garantia.TipoGarantia == 2 ? "Vehículo" : garantia.TipoInmueble},{" "}
          {garantia.Descripcion} {garantia.Modelo}
        </h4>
        <p>
          {garantia.Valor.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p>
          <span>{garantia.Propio ? "Propio" : "Aval"}</span>
        </p>
      </div>
      <div ref={avalRef} className="detalles">
        <div className="contenedor-tabla">
          <table>
            <thead>
              <th>Campo</th>
              <th>Valor</th>
            </thead>
            <tbody>
              <tr>
                <td>Nombre</td>
                <td>{garantia.Nombre}</td>
              </tr>
              <tr>
                <td>RFC</td>
                <td>{garantia.RFC}</td>
              </tr>
              <tr>
                <td>Telefono</td>
                <td>{garantia.Telefono}</td>
              </tr>
              <tr>
                <td>Celular</td>
                <td>{garantia.Celular}</td>
              </tr>
              <tr>
                <td>Calle</td>
                <td>{garantia.Calle}</td>
              </tr>
              <tr>
                <td>CodigoPostal</td>
                <td>{garantia.CodigoPostal}</td>
              </tr>
              <tr>
                <td>Arraigo </td>
                <td>
                  {garantia.ArraigoAnos} años, {garantia.ArraigoMeses} meses
                </td>
              </tr>

              <tr>
                <td>FechaNacimiento</td>
                <td>{garantia.FechaNacimiento}</td>
              </tr>
              <tr>
                <td>Colonia</td>
                <td>{garantia.Colonia}</td>
              </tr>
              <tr>
                <td>Municipio</td>
                <td>{garantia.Municipio}</td>
              </tr>
              <tr>
                <td>Estado</td>
                <td>{garantia.Estado}</td>
              </tr>
              <tr>
                <td>EstadoCivil</td>
                <td>{garantia.EstadoCivil}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Garantia;
