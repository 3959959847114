import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CampoTexto from "../../Componentes/CampoTexto/CampoTexto";
import Paginador from "../../Componentes/Paginador/Paginador";
import { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NotFound from "../../Componentes/NotFound/NotFound";
const Solicitudes = () => {
  const navigate = useNavigate();
  const [busqueda, setBusqueda] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [modalidades, setModalidades] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [solicitudes, setSolicitudes] = useState([]);

  const obtenerModalidades = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/modalidades`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setModalidades(res.data);
      } else {
      }
    } catch (error) {}
  };

  const obtenerSolicitudes = async () => {
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/solicitudes?nombre=${busqueda}&pagina=${pagina}&modalidad=${modalidad}`;
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (res.status === 200) {
        setSolicitudes(res.data.Solicitudes);
        setTotalPaginas(res.data.TotalPaginas);
      } else {
        setSolicitudes([]);
      }
    } catch (error) {
      setSolicitudes([]);
    }
  };

  useEffect(() => {
    obtenerModalidades();
  }, []);
  useEffect(() => {
    obtenerSolicitudes();
  }, [busqueda, modalidad, pagina]);
  return (
    <main className="solicitudes">
      <h1>Solicitudes</h1>

    

      <div className="contenedor-filtros">
        <div className="buscador">
          <CampoTexto
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
            label="Buscar por nombre"
          />
        </div>
        <FormControl size="small" style={{ width: "20%" }}>
          <InputLabel>Modalidad</InputLabel>
          <Select
            value={modalidad}
            onChange={(e) => setModalidad(e.target.value)}
          >
            <MenuItem value={""}>Todas</MenuItem>
            {modalidades.map((modalidad) => (
              <MenuItem value={modalidad.IdModalidad}>
                {modalidad.Modalidad}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {solicitudes.length === 0 ? (
        <NotFound title="No se encontraron solicitudes" />
      ) : (
        <>
          <Paginador
            pagina={pagina}
            handlerPagina={setPagina}
            totalPaginas={totalPaginas}
          />

          <div className="contenedor-tabla">
            <table>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Modalidad</th>
                  <th>Fecha de solicitud</th>
                  <th>Estado</th>
                  <th className="centrado">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {solicitudes.map((solicitud) => (
                  <tr key={solicitud.IdSolicitud}>
                    <td>{solicitud.Nombre}</td>
                    <td>{solicitud.Modalidad}</td>
                    <td>{solicitud.Fecha}</td>
                    <td>{solicitud.Estatus}</td>
                    <td>
                      <div className="contenedor-opciones">
                        <button>
                          <FontAwesomeIcon
                            onClick={() =>
                              navigate(`./${solicitud.IdSolicitud}`)
                            }
                            className="icono"
                            icon={faPenToSquare}
                          />
                        </button>
                        <button>
                          <FontAwesomeIcon className="icono" icon={faTrash} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </main>
  );
};

export default Solicitudes;
