import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import NotFound from "../../../../Componentes/NotFound/NotFound";
import { Button } from "@mui/material";
import Garantia from "./Componentes/Garantia";

const DatosGarantia = () => {
  const { id } = useParams();
  const [garantias, setGarantias] = useState([]);
  const obtenerGarantias = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/solicitud/datos-garantia?Id=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setGarantias(res.data.Garantias);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    obtenerGarantias();
  }, []);

  const json = {
    Nombre: "garantia.Nombre",
    RFC: "garantia.RFC",
    Telefono: "garantia.Telefono",
    Celular: "garantia.Celular",
    Calle: "garantia.Calle",
    CodigoPostal: "garantia.CodigoPostal",
    ArraigoAnos: "garantia.ArraigoAnos",
    ArraigoMeses: "garantia.ArraigoMeses",
    FechaNacimiento: "j",
    Colonia: "garantia.Colonia",
    Municipio: "garantia.Municipio",
    Estado: "garantia.Estado",
    EstadoCivil: "garantia.EstadoCivil",
    DatosConyuge: "garantia.DatosConyuge",
  };
  return (
    <>
      <h2>Datos de la Garantía</h2>
      {garantias.length === 0 ? (
        <NotFound title={"El usuario no ha cargado ninguna garantía"} />
      ) : null}

      <div className="contenedor-garantias">
            {garantias.map((garantia) => (
                <Garantia key={garantia.Id} garantia={garantia} />
            ))}
      </div>
    </>
  );
};

export default DatosGarantia;
