import { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const useRequest = (endpoint) => {
  const [texto, setTexto] = useState("hola desde hook");
  const [cargando, setCargando] = useState(false);
  const [response, setResponse] = useState(false);
  const [error, setError] = useState(false);
  const cookies = new Cookies();

  const get = async (params = false) => {
    setCargando(true);
    let url = `${process.env.REACT_APP_SERVER_ADDRESS}/${endpoint}`;
    if (params) {
      let queryString = Object.keys(params)
        .map(function (key) {
          return key + "=" + params[key];
        })
        .join("&");

      url = `${url}?${queryString}`;
    }

    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: cookies.get("token"),
          "Allow-Cross-Origin": "*",
        },
      });

      setCargando(false);
      setError(false);

      setResponse(res);
      return res;
    } catch (error) {
      
      setResponse(false);
      setCargando(false);
      setError(error.response);
      if(error.response === undefined){
        return {
          status: 500,
          data: {
            msg: "Error de conexión",
            TipoError: "Error de conexión",
          },
        }
      }
      return error.response;
      console.log(error.response);
    }
  };

  const post = async (payload, formdata = false) => {

    setCargando(true);
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/${endpoint}`;
    let body;
    let headers
    if(formdata){

      
      
      body = formdata
      headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": cookies.get("token"),
      }
    }
    else{
      body = JSON.stringify(payload)
      headers = {
        "Content-Type": "application/json",
        "Authorization": cookies.get("token"),
      }
    }
    try {
      
      const res = await axios.post(url, body, {
        headers: headers,
      });

      setCargando(false);
      setResponse(res);
      setError(false);
      return res
    } catch (error) {
      setCargando(false);
      if(error.response === undefined){
        return {
          status: 500,
          data: {
            msg: "Error de conexión",
            TipoError: "Error de conexión",
          },
        }
      }
      return error.response
     
      setResponse(false);
      setError(error);
    }
  };

  const put = async (payload, formdata = false) => {

    setCargando(true);
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/${endpoint}`;
    let body;
    let headers
    if(formdata){

      
      
      body = formdata
      headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": cookies.get("token"),
      }
    }
    else{
      body = JSON.stringify(payload)
      headers = {
        "Content-Type": "application/json",
        "Authorization": cookies.get("token"),
      }
    }
    try {
      
      const res = await axios.put(url, body, {
        headers: headers,
      });

      setCargando(false);
      setResponse(res);
      setError(false);
      return res
    } catch (error) {
      setCargando(false);
      if(error.response === undefined){
        return {
          status: 500,
          data: {
            msg: "Error de conexión",
            TipoError: "Error de conexión",
          },
        }
      }
      return error.response
     
      setResponse(false);
      setError(error);
    }
  };

  return { texto, get, error, response, cargando, post,put };
};

export default useRequest;
