import { useEffect, useState } from "react";
import useCookies from "react-cookie/es6/useCookies";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from "../Paginas/Index/SideBar/SideBar";
const ProtectorRutas = () => {
  //Obtener cookies
  const [cookies] = useCookies(["token"]);
  //State para verificar el token
  const [token, setToken] = useState(true);
  //Verificar si existe el token
  useEffect(() => {
    if (cookies.token === undefined) {
      setToken(false);
    } else {

      setToken(true);
    }
  }, []);
  if (token) {
    return (
      <main className="index">
        <SideBar />
        <Outlet />
         
      </main>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectorRutas;
