import useDatosSolicitante from "./Context/useDatosSolicitante";
import { Skeleton } from "@mui/material";

const DatosEstadoCivil = () => {
    const {datosSolicitante,peticionDatosSolicitante} = useDatosSolicitante();
    if (peticionDatosSolicitante.cargando){
      return <Skeleton  width="50%" height={600} />
    }
    return ( 
        <div className="datos-complemetarios">
        <h3>Estado Civil</h3>
        <div className="contenedor-datos">
          <div className="campo">
            <h4>Estado Civil</h4>
            <p>{datosSolicitante.EstadoCivil}</p>
          </div>
          <div className="campo">
            <h4>Conyuge</h4>
            <p>{datosSolicitante.NombreConyuge}</p>
          </div>
          
          <div className="campo">
            <h4>RFC</h4>
            <p>{datosSolicitante.RFCConyuge}</p>
          </div>
          <div className="campo">
            <h4>Fecha de Nacimiento</h4>
            <p>{datosSolicitante.FechaNacimientoConyuge}</p>
          </div>
          <div className="campo">
            <h4>Lugar de Nacimiento</h4>
            <p>{datosSolicitante.MunicipioNacimientoConyuge} {datosSolicitante.EstadoNacimientoConyuge}</p>
          </div>
        </div>
      </div>
     );
}
 
export default DatosEstadoCivil;