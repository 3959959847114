import { useEffect, useState } from "react";
import CampoTexto from "../../../Componentes/CampoTexto/CampoTexto";
import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const ProductoFinanciero = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(false);
  const [modalidades, setModalidades] = useState([]);
  const [tiposCredito, setTiposCredito] = useState([]);
  const [antiguedades, setAntiguedades] = useState([]);
  const [productoFinanciero, setProductoFinanciero] = useState({
    Activo: true,
    GraciaMaxima: 6,
    GraciaMinima: 0,
    IdAntiguedadFiscal: "2",
    IdModalidad: "d98786ff-f544-4569-9819-e8377c9cefd7",
    IdTipoCredito: "2",
    MontoMaximo: 1000000.0,
    MontoMinimo: 25000.0,
    PlazoMaximo: 96,
    PlazoMinimo: 12,
    Tasa: 10.0,
  });
  const obtenerAntiguedadesFiscales = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/antiguedades-fiscales`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setAntiguedades(res.data);
      }
    } catch (error) {}
  };

  const obtenerModalidades = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/modalidades`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setModalidades(res.data);
      }
    } catch (error) {}
  };

  const obtenerTiposDeCredito = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/tipos-credito`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setTiposCredito(res.data);
      }
    } catch (error) {}
  };

  const obtenerProductosFinancieros = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/producto-financiero?Id=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setProductoFinanciero(res.data);
      }
    } catch (error) {}
  };
  const crearproductoFinanciero = async (e) => {
    e.preventDefault();
    setCargando(true);
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/producto-financiero`;
    const form = new FormData();
    form.append("IdModalidad", productoFinanciero.IdModalidad);
    form.append("IdTipoCredito", productoFinanciero.IdTipoCredito);
    form.append("IdAntiguedadFiscal", productoFinanciero.IdAntiguedadFiscal);
    form.append("MontoMinimo", productoFinanciero.MontoMinimo);
    form.append("MontoMaximo", productoFinanciero.MontoMaximo);
    form.append("GraciaMinima", productoFinanciero.GraciaMinima);
    form.append("GraciaMaxima", productoFinanciero.GraciaMaxima);
    form.append("PlazoMinimo", productoFinanciero.PlazoMinimo);
    form.append("PlazoMaximo", productoFinanciero.PlazoMaximo);
    form.append("Tasa", productoFinanciero.Tasa);
    try {
      const res = await axios.post(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });
      setCargando(false);
      if (res.status === 200) {
        navigate("/Inicio/Catalogos/ProductosFinancieros");
      }
    } catch (error) {
      setCargando(false);
    }
  };
  const actualizarproductoFinanciero = async (e) => {
    e.preventDefault();
    setCargando(true);
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/producto-financiero`;
    const form = new FormData();

    form.append("IdProductoFinanciero", id);
    form.append("IdModalidad", productoFinanciero.IdModalidad);
    form.append("IdTipoCredito", productoFinanciero.IdTipoCredito);
    form.append("IdAntiguedadFiscal", productoFinanciero.IdAntiguedadFiscal);
    form.append("MontoMinimo", productoFinanciero.MontoMinimo);
    form.append("MontoMaximo", productoFinanciero.MontoMaximo);
    form.append("GraciaMinima", productoFinanciero.GraciaMinima);
    form.append("GraciaMaxima", productoFinanciero.GraciaMaxima);
    form.append("PlazoMinimo", productoFinanciero.PlazoMinimo);
    form.append("PlazoMaximo", productoFinanciero.PlazoMaximo);
    form.append("Tasa", productoFinanciero.Tasa);
    try {
      const res = await axios.put(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });
      setCargando(false);
      if (res.status === 200) {
        navigate("/Inicio/Catalogos/ProductosFinancieros");
      }
    } catch (error) {
      setCargando(false);
    }
  };

  const enviarFormulario = (e) => {
    e.preventDefault();
    if (id !== "Nuevo") {
      actualizarproductoFinanciero(e);
    } else {
      crearproductoFinanciero(e);
    }
  };
  useEffect(() => {
    obtenerModalidades();
    obtenerTiposDeCredito();
    obtenerAntiguedadesFiscales();
    if (id) {
      obtenerProductosFinancieros();
    }
  }, []);
  return (
    <main className="edicion-catalogo">
      <h1>Personalidad Jurídica</h1>
      <form onSubmit={(e) => enviarFormulario(e)}>
        <FormControl size="small">
          <InputLabel>Modalidad</InputLabel>
          <Select
            value={productoFinanciero.IdModalidad}
            onChange={(e) =>
              setProductoFinanciero({
                ...productoFinanciero,
                IdModalidad: e.target.value,
              })
            }
          >
            {modalidades.map((modalidad) => (
              <MenuItem
                key={modalidad.IdModalidad}
                value={modalidad.IdModalidad}
              >
                {modalidad.Modalidad}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel>Tipo de Crédito</InputLabel>
          <Select
            value={productoFinanciero.IdTipoCredito}
            onChange={(e) =>
              setProductoFinanciero({
                ...productoFinanciero,
                IdTipoCredito: e.target.value,
              })
            }
          >
            {tiposCredito.map((tipoCredito) => (
              <MenuItem
                key={tipoCredito.IdTipoCredito}
                value={tipoCredito.IdTipoCredito}
              >
                {tipoCredito.TipoCredito}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel>Antiguedad</InputLabel>
          <Select
            onChange={(e) =>
              setProductoFinanciero({
                ...productoFinanciero,
                IdAntiguedadFiscal: e.target.value,
              })
            }
            value={productoFinanciero.IdAntiguedadFiscal}
          >
            {antiguedades.map((antiguedad) => (
              <MenuItem
                key={antiguedad.IdAntiguedadFiscal}
                value={antiguedad.IdAntiguedadFiscal}
              >
                {antiguedad.AntiguedadFiscal}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <CampoTexto
          label="Monto Minimo"
          placeholder="productoFinanciero"
          name="productoFinanciero"
          required={true}
          value={productoFinanciero.MontoMinimo}
          onChange={(e) =>
            setProductoFinanciero({
              ...productoFinanciero,
              MontoMinimo: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Monto Máximo"
          placeholder="Monto Máximo"
          name="MontoMaximo"
          required={true}
          value={productoFinanciero.MontoMaximo}
          onChange={(e) =>
            setProductoFinanciero({
              ...productoFinanciero,
              MontoMaximo: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Gracia Mínima (Meses)"
          placeholder="Gracia Mínima (Meses)"
          name="GraciaMinima"
          required={true}
          value={productoFinanciero.GraciaMinima}
          onChange={(e) =>
            setProductoFinanciero({
              ...productoFinanciero,
              GraciaMinima: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Gracia Máxima (Meses)"
          placeholder="Gracia Máxima (Meses)"
          name="GraciaMaxima"
          required={true}
          value={productoFinanciero.GraciaMaxima}
          onChange={(e) =>
            setProductoFinanciero({
              ...productoFinanciero,
              GraciaMaxima: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Plazo Minimo (Meses)"
          placeholder="Plazo Minimo"
          name="PlazoMinimo"
          required={true}
          value={productoFinanciero.PlazoMinimo}
          onChange={(e) =>
            setProductoFinanciero({
              ...productoFinanciero,
              PlazoMinimo: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Plazo Máximo (Meses)"
          placeholder="Plazo Máximo"
          name="PlazoMaximo"
          required={true}
          value={productoFinanciero.PlazoMaximo}
          onChange={(e) =>
            setProductoFinanciero({
              ...productoFinanciero,
              PlazoMaximo: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Tasa %"
          placeholder="Tasa"
          name="Tasa"
          required={true}
          value={productoFinanciero.Tasa}
          onChange={(e) =>
            setProductoFinanciero({
              ...productoFinanciero,
              Tasa: e.target.value,
            })
          }
        />

        <Button disabled={cargando} variant="contained" type="submit">
          {cargando ? "Cargando..." : "Actualizar"}
        </Button>
      </form>
    </main>
  );
};

export default ProductoFinanciero;
