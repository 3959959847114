import { Button, TextField } from "@mui/material";
import { useRef, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import Cookies from "universal-cookie";

const IniciarSesion = () => {

  //Navigate
  const navigate = useNavigate();

  //Staate para manejar mensajer de error en los campos

  const [cargando, setCargando] = useState(false);
  
  const [correoElectronico, setcorreoElectronico] = useState({
    valor: "",
    error: false,
    msg: "",
  });

  const [contrasena, setContrasena] = useState({
    valor: "",
    error: false,
    msg: "",
  });

  
  const cookies = new Cookies();

  const nombreUsuario = cookies.get("Nombre")

  //Llamado api para autenticar usuario
  const autenticarUsuario = async (e) => {
    e.preventDefault();
    setCargando(true);
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/auth`;

    const body = {CorreoElectronico: correoElectronico.valor, Contrasena: contrasena.valor}
    try {
      const res = await axios.post(url, JSON.stringify(body), {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
      });
      if (res.status === 200) {
        cookies.set("token", res.data.token, { path: "/",expires: new Date(res.data.expiracion) });
        cookies.set("Nombre", res.data.nombre, { path: "/",expires: new Date(res.data.expiracion) });
        navigate("/Inicio");
      } 
    } catch (error) {
      console.log(error.response.status)

      if (error.response.status === 403) {
        setContrasena({ valor: contrasena.valor, error: true, msg: error.response.data.msg });
        setCargando(false);
      }
      else if (error.response.status == 401){
        setcorreoElectronico({
          valor: correoElectronico.valor,
          error: true,
          msg: error.response.data.msg,
        });
        setCargando(false);
      }

      if (correoElectronico.valor === "") {
        setcorreoElectronico({
          valor: correoElectronico.valor,
          error: true,
          msg: "El correo electrónico es requerido",
        });
        setCargando(false);
      }
      if (contrasena.valor === "") {
        setContrasena({
          valor: contrasena.valor,
          error: true,
          msg: "La contraseña es requerida",
        });
        setCargando(false);
      }

    }
  };

  //Manejar cambios en los campos
  const handleCorreoElectronico = (e) => {
    setcorreoElectronico({ valor: e.target.value, error: false, msg: "" });
  };
  const handlecontrasena = (e) => {
    setContrasena({ valor: e.target.value, error: false, msg: "" });
  };

  return (
    <main className="auth">
      <div className="iniciar-sesion">
        <div className="contenedor-izquierdo">
          <div className="contenedor-form">
            <div className="contenedor-textos">
              <h1>¡Bienvenido!</h1>
            </div>
            <form onSubmit={ e => autenticarUsuario (e)}>
              <TextField
                error={correoElectronico.error ? true : false}
                helperText={correoElectronico.msg}
                onChange={(e) => handleCorreoElectronico(e)}
                variant="outlined"
                size="small"
                label="Correo Electónico"
                value={correoElectronico.valor}
                required
              />
              <TextField
                error={contrasena.error ? true : false}
                helperText={contrasena.msg}
                onChange={(e) => handlecontrasena(e)}
                type="password"
                variant="outlined"
                label="Contraseña"
                size="small"
                required
              />

              <Button type="submit" variant="contained">
                {cargando ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Iniciar Sesión"
                )}
              </Button>


            </form>
          </div>
        </div>
      </div>
      
    </main>
  );
};

export default IniciarSesion;
