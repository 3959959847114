import {
  faFile,
  faMoneyCheck,
  faPerson,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Catalogos = () => {
  const navigate = useNavigate();

  return (
    <main className="catalogos">
      <h1>Catalogos</h1>

      <div className="contenedor-catalogos">
        <div
          onClick={() => navigate("./PersonalidadesJuridicas")}
          className="catalogo"
        >
          <FontAwesomeIcon icon={faPerson} />
          <h4>Personalidades Jurídicas</h4>
        </div>
        <div onClick={() => navigate("./Modalidades")} className="catalogo">
          <FontAwesomeIcon icon={faSuitcase} />
          <h4>Modalidades </h4>
        </div>{" "}
        <div
          onClick={() => navigate("./ProductosFinancieros")}
          className="catalogo"
        >
          <FontAwesomeIcon icon={faMoneyCheck} />
          <h4>Productos Financieros </h4>
        </div>
        <div onClick={() => navigate("./Documentos")} className="catalogo">
          <FontAwesomeIcon icon={faFile} />
          <h4>Documentos</h4>
        </div>
      </div>
    </main>
  );
};

export default Catalogos;
