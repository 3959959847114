import { useState } from "react";
import { useRef } from "react";
import Swal from "sweetalert2";

const Referencia = ({ referencia }) => {
  const avalRef = useRef(0);
  const [aval, setaval] = useState(false);
  const mostrarAval = () => {
    setaval(!aval);
    if (aval) {
      avalRef.current.style.display = "none";
    } else {
      avalRef.current.style.display = "block";
    }
  };
  return (
    <div onClick={mostrarAval} className="garantia">
      <div className="informacion">
        <h4>
         {referencia.Nombre}
        </h4>
        <p>
          {referencia.TipoRelacion}
        </p>

      </div>
      <div ref={avalRef} className="detalles">
        <div className="contenedor-tabla">
          <table>
            <thead>
              <th>Campo</th>
              <th>Valor</th>
            </thead>
            <tbody>
              <tr>
                <td>Nombre</td>
                <td>{referencia.Nombre}</td>
              </tr>
              <tr>
                <td>Telefono</td>
                <td>{referencia.Telefono}</td>
              </tr>
              <tr>
                <td>Celular</td>
                <td>{referencia.Celular}</td>
              </tr>
              <tr>
                <td>Calle</td>
                <td>{referencia.Calle}</td>
              </tr>
              <tr>
                <td>CodigoPostal</td>
                <td>{referencia.CodigoPostal}</td>
              </tr>
              <tr>
                <td>Arraigo </td>
                <td>
                  {referencia.ArraigoAnos} años, {referencia.ArraigoMeses} meses
                </td>
              </tr>

              <tr>
                <td>Colonia</td>
                <td>{referencia.Colonia}</td>
              </tr>
              <tr>
                <td>Tiempo de Relación</td>
                <td>{referencia.TiempoRelacionAnos} años, {referencia.TiempoRelacionMeses} meses</td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Referencia;
