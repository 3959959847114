import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CampoTexto from "../../Componentes/CampoTexto/CampoTexto";
import axios from "axios";
import { Cookies } from "react-cookie";
import Swal from "sweetalert2";
const AgregarAgente = () => {
  const { id } = useParams();

  const [nombre, setNombre] = useState("");
  const [password, setPassword] = useState("");
  const [correo, setCorreo] = useState("");
  const [esAdmin, setEsAdmin] = useState(false);
  const [cargando, setCargando] = useState(false);
  const cookies = new Cookies();

  const [agente, setAgente] = useState({
    CorreoElectronico: "",
    Nombre: "",
    Administrador: false,
    Estatus: false,
  });
  const obtenerAgente = async () => {
    const token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/agente?IdAgente=${id}`;
    try {
      const res = await axios.get(url, { headers: { Authorization: token } });
      if (res.status === 200) {
        setAgente(res.data);
      } else {
      }
    } catch (error) {
      Swal.fire(error.name, error.message, "error");
    }
  };
  const crearAgente = async () => {
    const token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/agente`;
    try {
      const res = await axios.post(url, JSON.stringify(agente), {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      console.log(res);
      if (res.status === 201) {
        Swal.fire("Agente creado", `Contraseña: ${res.data.at}`, "success");
      }
    } catch (error) {
      const res = error.response;
      console.log(res);
      if (res.status === 401) {
        Swal.fire(res.data, "", "info");
      }
    }
  };

  const actualizarAgente = async (e) => {
    e.preventDefault();
    const token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/agente`;
    const body = { ...agente, IdAgente: id };
    try {
      const res = await axios.put(url, JSON.stringify(body), {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (res.status === 200) {
        Swal.fire(
          "Agente actualizado",
          `Contraseña: ${res.data.at}`,
          "success"
        );
      }
    } catch (error) {
      const res = error.response;
      console.log(res);
    }
  };
  const enviarFormulario = (e) => {
    e.preventDefault();
    if (id === "Nuevo") {
      crearAgente();
    } else {
      actualizarAgente(e);
    }
  };

  useEffect(() => {
    if (id !== "Nuevo") {
      obtenerAgente();
    }
  }, []);

  return (
    <div className="editor-agentes">
      <form onSubmit={(e) => enviarFormulario(e)}>
        <h1>Crear Agente</h1>
        <CampoTexto
          loading={cargando}
          label="Nombre del Agente"
          value={agente.Nombre}
          fieldName={"Nombre"}
          onChange={(e) => setAgente({ ...agente, Nombre: e.target.value })}
        />
        <CampoTexto
          loading={cargando}
          label="Correo electrónico"
          value={agente.CorreoElectronico}
          fieldName={"CorreoElectronico"}
          onChange={(e) =>
            setAgente({ ...agente, CorreoElectronico: e.target.value })
          }
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={agente.Administrador}
                onChange={(e) =>
                  setAgente({ ...agente, Administrador: e.target.checked })
                }
              />
            }
            label="Administrador"
          />
          {id !== "Nuevo" ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={agente.Estatus}
                  onChange={(e) =>
                    setAgente({ ...agente, Estatus: e.target.checked })
                  }
                  label="Estatus"
                />
              }
              label="Activo"
            />
          ) : null}
        </FormGroup>

        <Button type="submit" variant="contained">
          Crear Agente
        </Button>
      </form>
    </div>
  );
};

export default AgregarAgente;
