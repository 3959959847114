import styled from "@emotion/styled";
import { Skeleton, TextField } from "@mui/material";

const CampoTexto = ({
  fieldName,
  label,
  value,
  onChange,
  type,
  loading,
  disabled = false,
}) => {
  return (
    <div className="contenedor-campo">
      {loading ? (
        <Skeleton width="100%" height="60px"  style={{margin:0}}/>
      ) : (
        <TextField
        fullWidth
          name={fieldName}
          label={label}
          size="small"
          type={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default CampoTexto;
