import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import useDatosSolicitante from "./Context/useDatosSolicitante";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
const DatosPersonales = () => {
  const { datosSolicitante, obtenerDatosSolicitante, peticionDatosSolicitante } = useDatosSolicitante();
  const formatearTelefono = (numero = false) => {
    if (!numero) {
      return "Sin teléfono";
    }
    const numeroLimpio = numero.replace(/\D/g, "");
    const formato = `(${numeroLimpio.slice(0, 3)}) ${numeroLimpio.slice(
      3,
      6
    )}-${numeroLimpio.slice(6, 10)}`;
    return formato;
  };
  useEffect(() => {
    obtenerDatosSolicitante();
  }, []);
  if (peticionDatosSolicitante.cargando) {
    return <Skeleton style={{height:"100px"}} />;
    }
  return (
    <div className="datos-personales">
      <div className="informacion">
        <h2>{datosSolicitante.Nombre}</h2>
        <div className="direccion">
          <h3>
            {datosSolicitante.Calle} {datosSolicitante.NumeroExterior}
          </h3>
          <h4>
            {datosSolicitante.Colonia}, {datosSolicitante.Municipio} C.P.
            {datosSolicitante.CodigoPostal}
          </h4>
 
        </div>
      </div>
      <div className="contacto">
        <div className="campo">
          <FontAwesomeIcon className="icono" icon={faEnvelope} />
          <p>{datosSolicitante.CorreoElectronico}</p>
        </div>
        <div className="campo">
          <FontAwesomeIcon className="icono" icon={faWhatsapp} />
          <p>
            {datosSolicitante.Telefono === ""
              ? "Sin teléfono"
              : datosSolicitante.Telefono}
          </p>
        </div>
        <div className="campo">
          <FontAwesomeIcon className="icono" icon={faPhone} />
          <p>{formatearTelefono(datosSolicitante.Celular)}</p>
        </div>
      </div>
    </div>
  );
};

export default DatosPersonales;
