import useDatosNegocio from "./Context/useDatosNegocio";
import { Skeleton } from "@mui/material";

const DatosComerciales = () => {
    const {datosNegocio,peticionDatosNegocio} = useDatosNegocio();
    if (peticionDatosNegocio.cargando){
      return <Skeleton  width="50%" height={600} />
    }
    return ( 
        <div className="datos-complemetarios">
        <h3>Datos Comerciales</h3>
        <div className="contenedor-datos">
          <div className="campo">
            <h4>RFC</h4>
            <p>{datosNegocio.RFC}</p>
          </div>
          <div className="campo">
            <h4>Actividad Económica</h4>
            <p>{datosNegocio.ActividadEspecifica}</p>
          </div>
          
          <div className="campo">
            <h4>Arraigo Domiciliar</h4>
            <p>{datosNegocio.ArraigoAnos} años, y {datosNegocio.ArraigoMeses} meses</p>
          </div>

        </div>
      </div>
     );
}
 
export default DatosComerciales;