import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Modalidades = () => {
  const navigate = useNavigate();
  const [modalidades, setModalidades] = useState([]);

  const obtenerModalidades = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/modalidades`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setModalidades(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    obtenerModalidades();
  }, []);

  return (
    <main className="catalogo">
      <h1>Modalidades</h1>
      <Button onClick={() => navigate("./Nuevo")} variant="contained" size="small">Crear Modalidad</Button>
      <div className="contenedor-tabla">
        <table>
          <thead>
            <tr>
              <th>Modalidad</th>
              <th className="centrado"> Acciones</th>
            </tr>
          </thead>
          <tbody>
            {modalidades.map((modalidad) => (
              <tr key={modalidad.IdModalidad}>
                <td>{modalidad.Modalidad}</td>
                <td>
                  <div className="contenedor-opciones">
                    <FontAwesomeIcon
                      className="icono"
                      icon={faPenToSquare}
                      onClick={() => navigate(`./${modalidad.IdModalidad}`)}

                    />
                    <FontAwesomeIcon
                      className="icono"
                      icon={faTrashCan}
                      onClick={() => Swal.fire("Ups","No puedes eliminar modalidades","warning")}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default Modalidades;
