import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import useRequest from "../../../../Hooks/useRequest/useRequest";
const Documento = ({ documento, actualizarLista }) => {

  const peticionDocumento = useRequest("admin/documento/modalidad");
  const { id } = useParams();
  const detallesRef = useRef(0);
  const [detalles, setDetalles] = useState(true);
  const mostrarDetalles = () => {
    setDetalles(!detalles);
    if (detalles) {
      detallesRef.current.style.display = "block";
    } else {
      detallesRef.current.style.display = "none";
    }
  };

  const asignarDocumento = async () => {
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/documento/modalidad`;

    try {
      const res = await axios.post(
        url,
        JSON.stringify({
          IdModalidad: id,
          IdDocumento: documento.IdDocumento,
          Preautorizacion: !documento.Asignado,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (res.status === 200) {
        Swal.fire("Asignación exitosa", "", "success");
        actualizarLista();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const desasingarDocumento = async () => {
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/documento/modalidad?Id=${documento.IdRegistro}`;

    try {
      const res = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (res.status === 200) {
        Swal.fire("Desasignación exitosa", "", "success");
        actualizarLista();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ejecutarAsignacion = (asignacion) => {
    console.log(asignacion);
    
    if (!asignacion) {
        desasingarDocumento()
    } else {
      asignarDocumento();
    }
  };

  const actualizarPreautorizacion = async (e) => {
    const response = await peticionDocumento.put({
      IdRegistro: documento.IdRegistro,
      Preautorizacion: e.target.checked
    });
    if(response.status === 200){
     
      actualizarLista();
    }
  }
  return (
    <div className="documento">
      <FormControlLabel
        control={
          <Switch
            checked={documento.Asignado}
            onChange={(e) => ejecutarAsignacion(e.target.checked)}
          />
        }
        label={documento.Documento}
      />
      <Button onClick={mostrarDetalles} size="small" variant="outlined">
        <FontAwesomeIcon icon={detalles ? faChevronDown : faChevronUp} />
      </Button>
      <div  ref={detallesRef} className="detalles">
        <p>{documento.Descripcion}</p>
        <FormControlLabel
    
          control={
            <Switch
              onChange={(e) => actualizarPreautorizacion(e)}
              checked={documento.Preautorizacion}
            />
          }
          label="Preautorización"
        />
      </div>
    </div>
  );
};

export default Documento;
