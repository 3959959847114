import Negocio from "./Negocio";
import useDatosNegocio from "./Context/useDatosNegocio";
import DatosNegocioProvider from "./Context/DatosNegocioContext";
import Filtro from "./Filtro";
import NotFound from "../../../../Componentes/NotFound/NotFound";
import DatosGenerales from "./DatosGenerales";
import DatosComerciales from "./DatosComerciales";
import DatosEmpleados from "./DatosEmpleados";

const DatosNegocio = () => {
  return (
    <DatosNegocioProvider>
      <>

      
        <h2>Datos del Negocio</h2>
        <DatosGenerales />
     
        <div className="contenedor-doble">
        <DatosComerciales />
        <DatosEmpleados/>
   
        </div>
        
      </>
    </DatosNegocioProvider>
  );
};

export default DatosNegocio;
