import Solicitante from "./Solicitante";
import useDatosSolicitante from "./Context/useDatosSolicitante";
import DatosSolicitanteProvider from "./Context/DatosSolicitanteContext";
import Filtro from "./Filtro";
import NotFound from "../../../../Componentes/NotFound/NotFound";

import DatosPersonales from "./DatosPersonales";
import DatosComplementarios from "./DatosComplementarios";
import DatosEstadoCivil from "./DatosEstadoCivil";

const DatosSolicitante = () => {
  return (
    <DatosSolicitanteProvider>
      <>
        <h2>Datos del Solicitante</h2>
        <DatosPersonales />
      <div className="contenedor-doble">
        <DatosComplementarios />
        <DatosEstadoCivil />
        </div>
 
      </>
    </DatosSolicitanteProvider>
  );
};

export default DatosSolicitante;
