import React, { createContext, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import useRequest from "../../../../../Hooks/useRequest/useRequest";
export const DatosNegocioContext = createContext();

const DatosNegocioProvider = (props) => {
  const { id } = useParams();
  const [datosNegocio, setDatosNegocio] = useState(false);
  const peticionDatosNegocio = useRequest("admin/solicitud/datos-negocio");
  const obtenerDatosNegocio = async () => {

      const response = await peticionDatosNegocio.get({ Id: id });
      if (response.status === 200) {
        setDatosNegocio(response.data);
      } else {
        setDatosNegocio([]);
      }

  };

  return (
    <DatosNegocioContext.Provider
      value={{
        datosNegocio,
        setDatosNegocio,
        obtenerDatosNegocio,
        peticionDatosNegocio,
      }}
    >
      {props.children}
    </DatosNegocioContext.Provider>
  );
};

export default DatosNegocioProvider;
