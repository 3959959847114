import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import NotFound from "../../../../Componentes/NotFound/NotFound";
const DatosCredito = () => {
  const { id } = useParams();
  const [creditos, setCreditos] = useState(false);

  const obtenerDatosCredito = async () => {
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/solicitud/datos-credito?Id=${id}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      if (res.status === 200) {
        setCreditos(res.data);
      } else {
        setCreditos([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    obtenerDatosCredito();
  }, []);

  if (!creditos) {
    return <h2>Cargando...</h2>;
  }
  if (creditos.length === 0) {
    return (
      <NotFound title={"El usuario aún no ha completado esta información"} />
    );
  }
  return (
    <>
      <h2>Datos del Credito</h2>

      <div className="contenedor-tabla">
        <table>
          <thead>
            <tr>
              <th>Tipo de Credito</th>
              <th>Monto</th>
              <th>Plazo</th>
              <th>Gracia</th>
            </tr>
          </thead>

          <tbody>
            {creditos.map((credito) => (
              <tr key={credito.IdCredito}>
                <td>{credito.TipoCredito}</td>
                <td>{credito.Monto.toLocaleString("es-MX", "Currency")}</td>
                <td>{credito.Plazo} meses</td>
                <td>{credito.Gracia} meses</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DatosCredito;
