import {
  faCircle,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const Documentos = () => {
  const navigate = useNavigate();
  const [documentos, setDocumentos] = useState([]);

  const obtenerDocumentos = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/documentos`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setDocumentos(res.data.Documentos);
      }
    } catch (error) {}
  };

  useEffect(() => {
    obtenerDocumentos();
  }, []);

  return (
    <main className="catalogo">
      <h1>Documentos</h1>
      <Button
        onClick={() => navigate("./Nuevo")}
        variant="contained"
        size="small"
      >
        Crear Documento
      </Button>
      <div className="contenedor-tabla">
        <table>
          <thead>
            <tr>
              <th>Documento</th>
              <th className="centrado">Estado</th>
              <th className="centrado"> Acciones</th>
            </tr>
          </thead>
          <tbody>
            {documentos.map((documento) => (
              <tr key={documento.IdDocumento}>
                <td>{documento.Documento}</td>
                <td className="centrado">
                  <FontAwesomeIcon
                    color={documento.Estado ? "green" : "red"}
                    icon={faCircle}
                  />
                </td>
                <td>
                  <div className="contenedor-opciones">
                    <FontAwesomeIcon
                      className="icono"
                      icon={faPenToSquare}
                      onClick={() => navigate(`./${documento.IdDocumento}`)}
                    />
                    <FontAwesomeIcon
                      className="icono"
                      icon={faTrashCan}
                      onClick={() =>
                        Swal.fire(
                          "Ups",
                          "No puedes eliminar documentos",
                          "warning"
                        )
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default Documentos;
