import { Button, FormGroup, Switch, FormControlLabel } from "@mui/material";
import Documento from "./Documento";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
const Documentos = () => {
  const { id } = useParams();
  const [documentos, setDocumentos] = useState([]);
  const obtenerDocumentos = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/admin/documentos/modalidad?IdModalidad=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setDocumentos(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    obtenerDocumentos();
  }, []);
  return (
    <>
      <h2>Documentos</h2>

      <FormGroup className="contenedor-documentos">
        {documentos.map((documento) => (
            <Documento key={documento.IdDocumento} actualizarLista={obtenerDocumentos} documento={documento} />
        ))}
      </FormGroup>
    </>
  );
};

export default Documentos;
