import { Menu, Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import useRequest from "../../../../Hooks/useRequest/useRequest";
const Documento = ({documento,tipo}) => {
    const [estatus, setEstatus] = useState(documento.IdEstatusDocumento)
    const peticionDocumento = useRequest("admin/documento/solicitud");


    const cambiarEstatus   = async (e)  => {
        
        const response = await peticionDocumento.put({
            IdAsignacion: documento.IdAsignacion,
            IdEstatusDocumento: e.target.value
        })
        setEstatus("-1")
        if(response.status === 200){
            console.log(response.data)
            setEstatus(response.data)
        }else{
            console.log(response)
        }
    }

    return ( 
        <tr>
        <td>{documento.Documento}</td>
        <td>
            <Select disabled={estatus == "-1"} onChange={e => cambiarEstatus(e)} value={estatus} size="small" fullWidth>
                <MenuItem value="-1">Actualizando</MenuItem>
                <MenuItem value="0">Pendiente</MenuItem>
                <MenuItem value="1">En revisión</MenuItem>
                <MenuItem value="2">Rechazado</MenuItem>
                <MenuItem value="3">Aceptado</MenuItem>

            </Select>
        </td>
        <td>Preautorizacion</td>
        <td>
          <div className="contenedor-opciones">
            <FontAwesomeIcon className="icono" icon={faEdit} />
          </div>
        </td>
      </tr>
     );
}
 
export default Documento;