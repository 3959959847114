import { useEffect, useState } from "react";
import CampoTexto from "../../../Componentes/CampoTexto/CampoTexto";
import { Button } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const PersonalidadJuridica = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cargando, setCargando] = useState(false);
  const [personalidadJuridica, setPersonalidadJuridica] = useState({
    PersonalidadJuridica: "",
    Descripcion: "",
    Ilustracion: false,
  });
  const obtenerPersonalidadJuridica = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/personalidad-juridica?Id=${id}`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setPersonalidadJuridica(res.data);
      }
    } catch (error) {}
  };

  const actualizarPersonalidadJuridica = async (e) => {
    e.preventDefault();
    setCargando(true);
    let token = document.cookie.split("=")[1].split(";")[0];
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/personalidad-juridica`;
    const form = new FormData();
    form.append("IdPersonalidadJuridica", id);
    form.append(
      "PersonalidadJuridica",
      personalidadJuridica.PersonalidadJuridica
    );
    form.append("Descripcion", personalidadJuridica.Descripcion);
    if (personalidadJuridica.Ilustracion) {
      form.append("Ilustracion", personalidadJuridica.Ilustracion[0]);
    }
    try {
      const res = await axios.put(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });
      setCargando(false);
      if (res.status === 200) {
        navigate("/Inicio/Catalogos/PersonalidadesJuridicas")
      }
    } catch (error) {
      setCargando(false);
    }
  };
  useEffect(() => {
    if (id) {
      obtenerPersonalidadJuridica();
    }
  }, []);
  return (
    <main className="edicion-catalogo">
      <h1>Personalidad Jurídica</h1>
      <form onSubmit={(e) => actualizarPersonalidadJuridica(e)}>
        <CampoTexto
          disabled={true}
          label="Personalidad Jurídica"
          placeholder="Personalidad Jurídica"
          name="personalidadJuridica"
          required={true}
          value={personalidadJuridica.PersonalidadJuridica}
          onChange={(e) =>
            setPersonalidadJuridica({
              ...personalidadJuridica,
              PersonalidadJuridica: e.target.value,
            })
          }
        />
        <CampoTexto
          label="Descripción"
          placeholder="Descripcin"
          name="Descripcion"
          required={true}
          value={personalidadJuridica.Descripcion}
          onChange={(e) =>
            setPersonalidadJuridica({
              ...personalidadJuridica,
              Descripcion: e.target.value,
            })
          }
        />
        <input
          type="file"
          onChange={(e) =>
            setPersonalidadJuridica({
              ...personalidadJuridica,
              Ilustracion: e.target.files,
            })
          }
        />
        <Button disabled={cargando} variant="contained" type="submit">
          {cargando ? "Cargando..." : "Actualizar"}
        </Button>
      </form>
    </main>
  );
};

export default PersonalidadJuridica;
