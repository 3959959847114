import { Skeleton } from "@mui/material";
import useDatosSolicitante from "./Context/useDatosSolicitante";

const DatosComplementarios = () => {
    const {datosSolicitante, peticionDatosSolicitante} = useDatosSolicitante();
    if (peticionDatosSolicitante.cargando){
      return <Skeleton  width="50%" height={600} />
    }
    return ( 
        <div className="datos-complemetarios">
        <h3>Datos Complementarios</h3>
        <div className="contenedor-datos">
        <div className="campo">
            <h4>RFC</h4>
            <p>{datosSolicitante.RFC}</p>
          </div>
        <div className="campo">
            <h4>Arraigo Domiciliar</h4>
            <p>{datosSolicitante.ArraigoAnos} año y {datosSolicitante.ArraigoMeses} meses</p>
          </div>
          <div className="campo">
            <h4>Fecha de Nacimiento</h4>
            <p>{datosSolicitante.FechaNacimiento}</p>
          </div>
          
          <div className="campo">
            <h4>Lugar de Nacimiento</h4>
            <p>{datosSolicitante.MunicipioNacimiento}, {datosSolicitante.EstadoNacimiento}</p>
          </div>
          <div className="campo">
            <h4>Dependientes Económicos</h4>
            <p>{datosSolicitante.DependientesEconomicos}</p>
          </div>
          <div className="campo">
            <h4>Grado de Estudios</h4>
            <p>{datosSolicitante.GradoEstudios}</p>
          </div>
        </div>
      </div>
     );
}
 
export default DatosComplementarios;