import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
const Paginador = ({ pagina, handlerPagina, totalPaginas }) => {
  const handlerPaginaAnterior = () => {
    if (pagina > 1) {
      handlerPagina(pagina - 1);
    }
  };
  const handlerPaginaSiguiente = () => {
    if (pagina < totalPaginas) {
      handlerPagina(pagina + 1);
    }
  };
  return (
    <div className="paginador">
      <button onClick={handlerPaginaAnterior}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <h4>{pagina}</h4>
      <button onClick={handlerPaginaSiguiente}>
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
};

export default Paginador;
