import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCreditCard, faBuilding, faHouse, faFile } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const Solicitud = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("");
  const seleccionarOpcion = (opcion,ruta) => {
      setOpcionSeleccionada(opcion)
      navigate(ruta)
  }
  return (
    <main className="solicitud ">
      {/*<img src="https://i.ytimg.com/vi/IQ9kDtbwoaw/maxresdefault.jpg" alt="" />*/}
      <div className="contenedor-acciones">
        <h3>Información</h3>
        <section className="control-acciones">
          <button onClick={() => seleccionarOpcion("Credito", `/Inicio/Solicitudes/${id}`)} className={opcionSeleccionada === "Credito" ? "seleccion" : ""}>
            <FontAwesomeIcon className="icono" icon={faCreditCard} />
            <p>Datos del Crédito</p>
          </button>
          <button  onClick={() => seleccionarOpcion("Solicitante", `/Inicio/Solicitudes/${id}/DatosSolicitante`)}  className={opcionSeleccionada === "Solicitante" ? "seleccion" : ""}>
            <FontAwesomeIcon className="icono" icon={faUser} />
            <p>Datos del Solicitante</p>
          </button>
          <button onClick={() => seleccionarOpcion("Negocio", `/Inicio/Solicitudes/${id}/DatosNegocio`)}  className={opcionSeleccionada === "Negocio" ? "seleccion" : ""}>
            <FontAwesomeIcon className="icono" icon={faBuilding} />
            <p>Datos del Negocio</p>
          </button>
          <button onClick={() => seleccionarOpcion("Garantia", `/Inicio/Solicitudes/${id}/DatosGarantia`)}  className={opcionSeleccionada === "Garantia" ? "seleccion" : ""}>
            <FontAwesomeIcon className="icono" icon={faHouse} />
            <p>Datos del Garantia</p>
          </button>
          <button onClick={() => seleccionarOpcion("Referencias", `/Inicio/Solicitudes/${id}/DatosReferencias`)}  className={opcionSeleccionada === "Referencias" ? "seleccion" : ""}>
            <FontAwesomeIcon className="icono" icon={faHouse} />
            <p>Datos Referencias</p>
          </button>
          <button onClick={() => seleccionarOpcion("Documentos", `/Inicio/Solicitudes/${id}/Documentos`)}  className={opcionSeleccionada === "Referencias" ? "seleccion" : ""}>
            <FontAwesomeIcon className="icono" icon={faFile} />
            <p>Documentos</p>
          </button>
        </section>
      </div>
      <div className="outlet">
        <Outlet />
      </div>
    </main>
  );
};

export default Solicitud;
