import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
const PersonalidadesJuridicas = () => {
  const navigate = useNavigate();
  const [personalidades, setPersonalidades] = useState([]);

  const obtenerPersonalidadesJuridicas = async () => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/api/personalidades-juridicas`;
    try {
      const res = await axios.get(url);
      if (res.status === 200) {
        setPersonalidades(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    obtenerPersonalidadesJuridicas();
  }, []);

  return (
    <main className="catalogo">
      <h1>Personalidades Jurídicas</h1>
      <div className="contenedor-tabla">
        <table>
          <thead>
            <tr>
              <th>Personalidad Jurídica</th>
              <th className="centrado"> Acciones</th>
            </tr>
          </thead>
          <tbody>
            {personalidades.map((personalidad) => (
              <tr key={personalidad.IdPersonalidadJuridica}>
                <td>{personalidad.PersonalidadJuridica}</td>
                <td>
                  <div className="contenedor-opciones">
                    <FontAwesomeIcon
                      className="icono"
                      icon={faPenToSquare}
                      onClick={() => navigate(`./${personalidad.IdPersonalidadJuridica}`)}

                    />
                    <FontAwesomeIcon
                      className="icono"
                      icon={faTrashCan}
                      onClick={() => Swal.fire("Ups","No puedes eliminar personalidades jurídicas","warning")}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default PersonalidadesJuridicas;
