import useDatosNegocio from "./Context/useDatosNegocio";
import { Skeleton } from "@mui/material";

const DatosEmpleados = () => {
    const {datosNegocio,peticionDatosNegocio} = useDatosNegocio();
    if (peticionDatosNegocio.cargando){
      return <Skeleton  width="50%" height={600} />
    }
    return ( 
      <>
        <div className="datos-complemetarios">
        <h3>Empleos Actuales</h3>
        <div className="contenedor-datos">
          <div className="campo">
            <h4>Hombres</h4>
            <p>{datosNegocio.EmpleosHombresActuales}</p>
          </div>
         <div className="campo">
            <h4>Mujeres</h4>
            <p>{datosNegocio.EmpleosMujeresActuales}</p>
          </div>
          <div className="campo">
            <h4>Salario Promedio</h4>
            <p>{datosNegocio.SalarioPromedioActual}</p>
          </div>
          
          
     

        </div>
      </div>
      <div className="datos-complemetarios">
        <h3>Empleos a Generar</h3>
        <div className="contenedor-datos">
          <div className="campo">
            <h4>Hombres</h4>
            <p>{datosNegocio.EmpleosHombresAGenerar}</p>
          </div>
         <div className="campo">
            <h4>Mujeres</h4>
            <p>{datosNegocio.EmpleosMujeresAGenerar}</p>
          </div>
          <div className="campo">
            <h4>Salario Promedio</h4>
            <p>{datosNegocio.SalarioPromedioAGenerar}</p>
          </div>
          
          
     

        </div>
      </div>
      </>
     );
}
 
export default DatosEmpleados;