import "./Estilos/index.css";
import IniciarSesion from "./Paginas/Auth/Iniciar-Sesion";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectorRutas from "./Paginas/ProtectorRutas";
import Agentes from "./Paginas/Agentes/Agentes";
import AgregarAgente from "./Paginas/Agentes/AgregarAgente";
import Usuarios from "./Paginas/Usuarios/Usuarios";
import Usuario from "./Paginas/Usuarios/Usuario/Usuario";
import Acticvidad from "./Paginas/Usuarios/Usuario/Actividad";
import Solicitudes from "./Paginas/Solicitudes/Solicitudes";
import Solicitud from "./Paginas/Solicitudes/Solicitud/Solicitud";
import DatosSolicitante from "./Paginas/Solicitudes/Solicitud/DatosSolicitante/DatosSolicitante";
import DatosCredito from "./Paginas/Solicitudes/Solicitud/DatosCredito/DatosCredito";
import DatosNegocio from "./Paginas/Solicitudes/Solicitud/DatosNegocio/DatosNegocio";
import DatosGarantia from "./Paginas/Solicitudes/Solicitud/DatosGarantia/DatosGarantia";
import DatosReferencias from "./Paginas/Solicitudes/Solicitud/DatosReferencias/DatosReferencias";
import Catalogos from "./Paginas/Catalogos/Catalogos";
import PersonalidadesJuridicas from "./Paginas/Catalogos/PersonalidadesJuridicas/PersonalidadesJuridicas";
import PersonalidadJuridica from "./Paginas/Catalogos/PersonalidadesJuridicas/PersonalidadJuridica";
import Modalidades from "./Paginas/Catalogos/Modalidades/Modalidades";
import Modalidad from "./Paginas/Catalogos/Modalidades/Modalidad";
import ProductosFinancieros from "./Paginas/Catalogos/ProductosFinancieros/ProductosFinancieras";
import ProductoFinanciero from "./Paginas/Catalogos/ProductosFinancieros/ProductoFinanciero";
import Documentos from "./Paginas/Catalogos/Documentos/Documentos";
import Documento from "./Paginas/Catalogos/Documentos/Documento";
import {default as DocumentosModalidad}  from "./Paginas/Catalogos/Modalidades/Componentes/Documentos"; 
import DocumentosSolicitante from "./Paginas/Solicitudes/Solicitud/Documentos/Documentos";
import Dashboard from "./Paginas/Dashboard/Dashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IniciarSesion />} />
          <Route path="/Inicio" element={<ProtectorRutas />}>
            <Route index element={<Dashboard/>} />
            <Route path="Catalogos" element={<Catalogos/>} />
            <Route path="Catalogos/PersonalidadesJuridicas" element={<PersonalidadesJuridicas/>} />
            <Route path="Catalogos/PersonalidadesJuridicas/:id" element={<PersonalidadJuridica/>} />
            <Route path="Catalogos/Modalidades" element={<Modalidades/>} />
            <Route path="Catalogos/Modalidades/:id" element={<Modalidad/>} >

              <Route path="Documentos" element={<DocumentosModalidad/>} />
            </Route>

            <Route path="Catalogos/ProductosFinancieros" element={<ProductosFinancieros/>} />
            <Route path="Catalogos/ProductosFinancieros/:id" element={<ProductoFinanciero/>} />
            <Route path="Catalogos/Documentos" element={<Documentos/>} />
            <Route path="Catalogos/Documentos/:id" element={<Documento/>} />
            <Route path="Solicitudes" element={<Solicitudes />} />
            <Route path="Solicitudes/:id" element={<Solicitud />}>
              <Route index element={<DatosCredito />} />
              <Route path="DatosSolicitante" element={<DatosSolicitante />} />
              <Route path="DatosNegocio" element={<DatosNegocio />} />
              <Route path="DatosGarantia" element={<DatosGarantia/>} />
              <Route path="DatosReferencias" element={<DatosReferencias/>} />
              <Route path="Documentos" element={<DocumentosSolicitante/>} />
            </Route>

            <Route path="Usuarios" element={<Usuarios />} />
            <Route path="Usuarios/:id" element={<Usuario />} />
            <Route path="Usuarios/:id/Actividad" element={<Acticvidad />} />

            <Route path="Agentes" element={<Agentes />} />
            <Route path="Agentes/NuevoAgente/:id" element={<AgregarAgente />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
